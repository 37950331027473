.welcome-background {
  z-index: -1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  background: url("main-bg.jpg") center center / cover no-repeat;
  animation: fade-in 3s ease-in;
}

.welcome-background.active {
  opacity: 0.2;
}

.my-node-enter .welcome-background {
  opacity: 0;
}
.my-node-enter-active .welcome-background {
  opacity: 0.2;
  transition: opacity 500ms;
}
.my-node-exit .welcome-background {
  opacity: 0.2;
}
.my-node-exit-active .welcome-background {
  opacity: 0;
  transition: opacity 500ms;
}

.welcome {
  text-align: center;
  color: white;
  align-items: center;
  justify-content: center;
  height: 100vh;
  /* animation: screen-background 20s infinite 1s; */
}

.welcome a {
  color: white;
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: text-decoration-color 0.3s ease-in-out;
}

.welcome a:hover {
  text-decoration-color: white;
}

.welcome .greeting {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.welcome .greeting.active {
  opacity: 1;
}

.welcome .greeting .name {
  text-align: left;
}

.welcome .greeting .name h2 {
  margin-bottom: -1em;
}

.welcome .with-animated-color {
  animation: color 120s infinite 0s;
}

.welcome .values {
  font-size: 2em;
}

.welcome .about-me {
  gap: 1em;
  justify-content: center;
  margin: 2em;
}

.welcome .see-projects {
  font-size: 150%;
}

.welcome .icon {
  transition: transform 0.5s ease-in-out;
}

.welcome .icon:hover {
  transform: scale(1.1);
}

@keyframes color {
  0% {
    color: white;
  }

  16% {
    color: salmon;
  }

  33% {
    color: aqua;
  }

  50% {
    color: darkolivegreen;
  }

  67% {
    color: bisque;
  }

  83% {
    color: hotpink;
  }

  100% {
    color: white;
  }
}

@keyframes screen-background {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.2;
  }
}

@media only screen and (max-width: 600px) {
  .welcome .greeting .name {
    text-align: center;
  }
}
