.home {
  height: 100vh;
}

button.text-button {
  background-color: transparent;
  color: white;
  border: none;
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: text-decoration 0.1s ease-in-out;
}

button.text-button:active {
  text-decoration-color: white;
}

h1 {
  font-size: 4em;
}

h2 {
  font-size: 2em;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  h1 {
    font-size: 2.5em;
  }
  h2 {
    font-size: 1.25em;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  h1 {
    font-size: 3em;
  }
  h2 {
    font-size: 1.5em;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  h1 {
    font-size: 4em;
  }
  h2 {
    font-size: 2em;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  h1 {
    font-size: 4em;
  }
  h2 {
    font-size: 2em;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  h1 {
    font-size: 4.5em;
  }
  h2 {
    font-size: 2.25em;
  }
}
