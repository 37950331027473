* {
  scrollbar-width: initial;
  scrollbar-color: white black;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: black;
}

*::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 20px;
  border: 3px solid black;
}

body {
  background-color: black;
  color: white;
}

section {
  display: flex;
  flex-direction: column;
}

.fluid-container {
  padding-left: 32px;
  padding-right: 32px;
  max-width: none !important;
}

.container {
  padding-left: 32px;
  padding-right: 32px;
  max-width: none;
  margin: 0 auto;
}

.button {
  text-decoration: none;
  padding: 0.75em;
  border: 1px solid #222;
  border-radius: 10px;
  outline: none;
  background-color: #222;
  color: #ddd;
  transition: 0.5s;
}

/* .button.selected {
  background-color: #333;
  color: #fff;
  font-weight: 500;
  border: 1px solid #777;
}

.button:not(.selected):active {
  background-color: #222;
  color: #eee;
} */

.button:hover {
  background-color: #333;
  cursor: pointer;
}

.button:active {
  background-color: #111;
  cursor: pointer;
}

.button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(150,150,150, 0.4);
}

.button.scale-up-on-hover:hover {
  transform: scale(1.05);
}

/* .grid {
  display: grid;
  gap: 10px;
} */

.card {
  border-radius: 0.5em;
  background: #222222aa;
  border: 1px solid #1f1f1f;
  display: grid;
}

.card .header {
  margin: 0.5em;
}

.card .body {
  margin-left: 0.5em;
  margin-right: 0.5em;
  min-height: 50px;
}

.card .footer {
  margin: 0.5em;
}

.icons {
  display: flex;
  gap: 0.5em;
}

.icon {
  height: 1.5em;
  width: 1.5em;
}

.my-node-enter {
  opacity: 0;
}
.my-node-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.my-node-exit {
  opacity: 1;
}
.my-node-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

/* Helper methods */
.text-centered {
  text-align: center;
}

/* Smaller devices (phones, 600px and down) */
@media only screen and (max-width: 450px) {
  /* .grid {
    grid-template-columns: repeat(1, 1fr);
  } */
}

@media only screen and (min-width: 450px) {
  /* .grid {
    grid-template-columns: repeat(2, 1fr);
  } */
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  /* .grid {
    grid-template-columns: repeat(3, 1fr);
  } */
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  /* .grid {
    grid-template-columns: repeat(4, 1fr);
  } */
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  /* .grid {
    grid-template-columns: repeat(5, 1fr);
  } */
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1600px) {
  /* .grid {
    grid-template-columns: repeat(6, 1fr);
  } */
}

@media only screen and (min-width: 1024px) {
  .container {
    max-width: 960px;
  }
}

/* @media only screen and (min-width: 1216px) and (max-width: 1407px) {
  .container {
    max-width: 1152px;
  }
}

@media only screen and (min-width: 1408px) {
  .container {
    max-width: 1344px;
  }
} */
