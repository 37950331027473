.tags-container {
  display: flex;
  gap: 0.25em;
  margin-top: 0.75em;
  margin-bottom: 0.5em;
  flex-wrap: wrap;
}

.tags-container .tag {
  padding: .5em 1em;
  border-radius: 0.5em;
  outline: none;
  background-color: #111;
  color: #ddd;
  transition: 0.5s;
  font-size: x-small;
}

.tags-container .tag.clickable {
  cursor: pointer;
}
