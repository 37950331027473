.photos > .title {
  text-align: center;
}

.photos .pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 0;
}

.photos .actions {
  display: flex;
  flex-direction: row;
  margin: 1em 0;
  justify-content: space-between;
}

.photos .actions .tags-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.photos .tag {
  padding: 0.75em;
  border: 1px solid #111;
  border-radius: 10px;
  outline: none;
  background-color: #111;
  color: #ddd;
  transition: 0.5s;
}

.photos .selected {
  background-color: #333;
  color: #fff;
  font-weight: 500;
  border: 1px solid #777;
}

.hidden {
  opacity: 0;
}

.photos .tag:not(.selected):focus {
  background-color: #222;
  color: #eee;
}

.photos .photos-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem 0.5rem;
}

@media only screen and (max-width: 600px) {
  .photos .photos-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

.photos .photos-container .wrapper {
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease;
  overflow: hidden;
}

.photos .photos-container .wrapper .location {
  font-size: x-small;
  color: #777;
}

.photos .photos-container .wrapper .notes {
  font-size: small;
}

.photos .photos-container .wrapper img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
  transition: all 0.5s ease;
  opacity: 0;
}

.photos .photos-container .wrapper img.photo:hover {
  transform: scale(1.02);
  cursor: pointer;
}
