.header {
  display: flex;
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 32px;
}

.header a {
  text-decoration: none;
  color: white;
}

.header .logo {
  display: flex;
  align-items: center;
  flex: 0 1 auto;
}

.header .logo .image img {
  border-radius: 100%;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.header .logo .image {
  width: 30px;
  height: 30px;
  padding-right: 0.7em;
}

.header .logo .name {
  font-size: 110%;
  margin-bottom: 3px;
}

.header .menu {
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  gap: 1rem;
  margin-left: 2rem;
  margin-right: 1rem;
}

.header .menu a.selected {
  text-decoration: underline;
}

.header .menu a.item {
  padding-bottom: 4px;
}

@media only screen and (max-width: 600px) {
  /* .header .logo .name {
    display: none;
  } */

  .header .menu {
    display: none !important;
  }
}
