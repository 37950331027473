.projects > .title {
  text-align: center;
}

.projects .filter,
.project .filter {
  background-color: #222;
  padding: 1em;
  font-size: 1em;
  border-radius: 10px;
  outline: none;
  border: 1px solid #111;
  color: #fff;
  transition: background-color 0.1s ease-in-out;
}

.projects .filter:focus,
.project .filter:focus {
  background-color: #2a2a2a;
}

.projects .actions {
  display: flex;
  flex-direction: row;
  margin: 1em 0;
  justify-content: space-between;
}

.projects .actions .tags-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.projects .tag {
  padding: 0.75em;
  border: 1px solid #111;
  border-radius: 10px;
  outline: none;
  background-color: #111;
  color: #ddd;
  transition: 0.5s;
}

.projects .tag.selected {
  background-color: #333;
  color: #fff;
  font-weight: 500;
  border: 1px solid #777;
}

.project .tag.hidden {
  display: none;
}

.projects .tag:not(.selected):focus {
  background-color: #222;
  color: #eee;
}

.projects .results {
  margin-bottom: 2em;
  gap: 20px;
}

.projects .results.showing {
  opacity: 1;
}

.projects .card {
  height: 100%;
}

.projects .card .project-title {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.projects .card .project-title .year {
  font-size: x-small;
  color: #aaa;
  margin-top: 0.5em;
}

.projects .card .project-title .link {
  display: flex;
}

.projects .card .clickable-title {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.projects .card .clickable-title .icon {
  width: 1em;
  height: 1em;
}

.projects .description {
  color: #aaa;
}

.projects .tags {
  display: flex;
  gap: 0.25em;
  margin-top: 0.75em;
  margin-bottom: 0.5em;
  flex-wrap: wrap;
}

.projects .badge {
  font-size: x-small;
  padding: 0.5em;
  border-radius: 0.5em;
}

.projects .badge.clickable {
  cursor: pointer;
}

.projects .badge.removable::before {
  content: "\00d7  ";
}

.projects .see-projects span::after {
  content: " →";
  font-size: 1.5rem;
  margin-top: 5rem;
  margin-bottom: 10rem;
}

/* .projects .tag.selected::before {
  content: "✓ ";
} */

@media only screen and (max-width: 600px) {
  .view-featured-project {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .view-featured-project .image {
    grid-column: 1 / 2 !important;
    grid-row-start: 1 !important;
  }

  .view-featured-project .details {
    grid-column: 1 / 2;
    grid-row-start: 2 !important;
    margin-top: -2rem;
  }
}
