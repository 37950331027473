.listening-live {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100px;
  z-index: 1;
  display: flex;
}

.listening-live .artist-link {
  text-decoration: none;
  color: #ccc;
}

.listening-live .artist-link:hover {
  text-decoration: underline;
  color: #fff;
}

.listening-live > .card {
  flex: 1;
  background-color: #111;
  border-radius: 0;
}

.listening-live > .card > .body {
  display: flex;
  align-items: center;
  padding: 1em;
}

.listening-live .image {
  width: auto;
  height: 80px;
  object-fit: contain;
  margin-left: -0.5em;
}

.listening-live .track-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 10;
  gap: 1em;
  padding: 0.5em 0.75em;
}

.listening-live.mobile .track-info {
  flex-direction: column;
  align-items: unset;
}

.listening-live .track-title {
  display: flex;
  gap: 0.25em;
}

.listening-live .album-artist-info {
  display: block;
  font-size: small;
  /* margin-top: -0.25em; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* animation: slide 1s ease-in-out 1s infinite; */
}

.listening-live.mobile .album-artist-info {
  margin-top: 0;
}

.listening-live .track-title-album {
  width: 200px;
}

.listening-live.mobile .track-title-album {
  width: 100%;
}

.listening-live .track-progress {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  flex: 1 0 auto;
}

.listening-live .track-progress .total {
  background-color: #222;
  height: 5px;
  width: 100%;
}

.listening-live .track-progress .actual {
  background-color: #aaa;
  height: 5px;
  transition: width 50ms ease-in-out;
}
