.grid {
  display: grid;
  gap: 10px;
}



/* Smaller devices (phones, 600px and down) */
@media only screen and (max-width: 450px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .grid>.item:last-child {
    display: none;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .grid {
    grid-template-columns: 1fr;
  }

  .grid>.item:last-child {
    display: none;
  }
}

@media only screen and (min-width: 600px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .grid>.item:last-child {
    display: none;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .grid {
    grid-template-columns: repeat(4, 1fr);
  }

  .grid>.item:last-child {
    display: none;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .grid {
    grid-template-columns: repeat(5, 1fr);
  }

  .grid>.item:last-child {
    display: flex;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .grid {
    grid-template-columns: repeat(5, 1fr);
  }

  .grid>.item:last-child {
    display: flex;
  }
}
