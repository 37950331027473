.project .synopsis {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  color: #aaa;
}

.project .section {
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.project .description {
  display: flex;
  flex-direction: column;
  background-color: #1a1a1a;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
}

.project .title-container {
  display: flex;
  justify-content: space-between;
  margin: 0px 0px 0px 1rem;
}

.project .title-container .try {
  display: flex;
  align-items: center;
}

.project .spin-info-container {
  margin: -1.5rem 0 1.5rem 1rem;
}

.project .spin-info-container .spin-info {
  display: flex;
  gap: 0.5rem;
}

.project .spin-info-container .details {
  display: block;
  color: #aaa;
  font-size: small;
  margin-bottom: 1rem;;
}

.project .spin-info .filter {
  flex: 1;
}
