.spotify .track {
  display: flex;
  flex-direction: column;
  background-color: #181818a0;
  border-radius: 0.65em;
  padding: 1.5em;
  color: #fff;
  text-decoration: none;
  overflow: hidden;
  transition: background-color 300ms ease-in-out;
}

.spotify .track:hover {
  background-color: #282828a0;
}

.spotify .track .name {
  font-size: large;
  font-weight: bolder;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.spotify .track .image {
  margin-bottom: 1em;
  border-radius: 0.5em;
  width: 100%;
  height: auto;
  object-fit: cover;
}
