.spotify .artist {
  display: flex;
  flex-direction: column;
  background-color: #181818a0;
  border-radius: 0.65em;
  padding: 1.5em;
  color: #fff;
  text-decoration: none;
  overflow: hidden;
  transition: background-color 300ms ease-in-out;
}

.spotify .artist:hover:not(.detailed) {
  background-color: #282828a0;
}

.spotify .artist .name {
  font-size: large;
  font-weight: bolder;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.spotify .artist .image {
  margin-bottom: 1em;
  border-radius: 500px;
}

.spotify .artist.detailed {
  display: flex;
  gap: 1.5rem;
  flex-direction: row;
}

.spotify .artist.detailed .name {
  font-size: x-large;
}

.spotify .artist.detailed .image {
  margin-bottom: 0;
}
